'use client';

import { useLayoutEffect } from 'react';
import { useIsMounted } from 'usehooks-ts';

// when clicking a link, user will not scroll to the top of the page if the header is sticky.
// their current scroll position will persist to the next page.
// this useEffect is a workaround to 'fix' that behavior.
// issue link: https://github.com/vercel/next.js/issues/64441

export default function ScrollTop() {
  const isMounted = useIsMounted();

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [isMounted]);

  return null;
}

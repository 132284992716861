import(/* webpackMode: "eager" */ "/app/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/analytic/components/google-analytic/google-analytic-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/cookie-accept-popup/cookie-accept-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/scroll-top/scroll-top.tsx");
